.bgimg {
  position: fixed;
  background-position: center;
  left: 0;
  right: 0;
  z-index: 1;
  display: block;
  background-image: url("https://i.imgur.com/11wc2cs.jpeg");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}
